/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ConversionTask
 */
export interface ConversionTask {
    /**
     * 
     * @type {string}
     * @memberof ConversionTask
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversionTask
     */
    'progress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversionTask
     */
    'start_time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversionTask
     */
    'end_time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversionTask
     */
    'conversion_job_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversionTask
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversionTask
     */
    'updated_at'?: string;
    /**
     * 
     * @type {ConversionTaskMeta}
     * @memberof ConversionTask
     */
    'meta'?: ConversionTaskMeta;
}
/**
 * 
 * @export
 * @interface ConversionTaskMeta
 */
export interface ConversionTaskMeta {
    /**
     * 
     * @type {string}
     * @memberof ConversionTaskMeta
     */
    'dest_resource_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversionTaskMeta
     */
    'dest_version_id'?: string | null;
}
/**
 * 
 * @export
 * @interface ConvertAnonymRequest
 */
export interface ConvertAnonymRequest {
    /**
     * Active Storage signeID
     * @type {string}
     * @memberof ConvertAnonymRequest
     */
    'input_file': string;
}
/**
 * 
 * @export
 * @interface ResourceAuthPasswordRequest
 */
export interface ResourceAuthPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResourceAuthPasswordRequest
     */
    'link_password'?: string;
}
/**
 * 
 * @export
 * @interface UpdateShareOptionsRequest
 */
export interface UpdateShareOptionsRequest {
    /**
     * Resource view access by link, possible values :none, :view
     * @type {string}
     * @memberof UpdateShareOptionsRequest
     */
    'link_access'?: UpdateShareOptionsRequestLinkAccessEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateShareOptionsRequest
     */
    'link_password'?: string;
}

export const UpdateShareOptionsRequestLinkAccessEnum = {
    None: 'none',
    View: 'view'
} as const;

export type UpdateShareOptionsRequestLinkAccessEnum = typeof UpdateShareOptionsRequestLinkAccessEnum[keyof typeof UpdateShareOptionsRequestLinkAccessEnum];


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Start anonym conversion
         * @summary convert_anonym op
         * @param {ConvertAnonymRequest} [convertAnonymRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertAnonym: async (convertAnonymRequest?: ConvertAnonymRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/op/convert-anonym`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(convertAnonymRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get description of Conversion Task
         * @summary show conversion
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConversion', 'id', id)
            const localVarPath = `/api/v1/conversions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get logs of Conversion Task (redirect to logs file)
         * @summary logs conversion
         * @param {string} id Conversion Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversionLogs: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConversionLogs', 'id', id)
            const localVarPath = `/api/v1/conversions/{id}/logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List conversion tasks for user
         * @summary List conversions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/conversions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary show resource
         * @param {string} id Resource id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResource: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getResource', 'id', id)
            const localVarPath = `/api/v1/resources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary show resource\'s share_options
         * @param {string} id Resource id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShareOptions: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getShareOptions', 'id', id)
            const localVarPath = `/api/v1/resources/{id}/share-options`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary show version
         * @param {string} id Version id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVersion', 'id', id)
            const localVarPath = `/api/v1/versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary files version
         * @param {string} id Version id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionFiles: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVersionFiles', 'id', id)
            const localVarPath = `/api/v1/versions/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary enter password for resource share_option
         * @param {string} id Resource id
         * @param {ResourceAuthPasswordRequest} [resourceAuthPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAuthPassword: async (id: string, resourceAuthPasswordRequest?: ResourceAuthPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resourceAuthPassword', 'id', id)
            const localVarPath = `/api/v1/resources/{id}/share-options/auth-password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resourceAuthPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update resource\'s share_options
         * @param {string} id Resource id
         * @param {UpdateShareOptionsRequest} [updateShareOptionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShareOptions: async (id: string, updateShareOptionsRequest?: UpdateShareOptionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateShareOptions', 'id', id)
            const localVarPath = `/api/v1/resources/{id}/share-options`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateShareOptionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Start anonym conversion
         * @summary convert_anonym op
         * @param {ConvertAnonymRequest} [convertAnonymRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertAnonym(convertAnonymRequest?: ConvertAnonymRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversionTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertAnonym(convertAnonymRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get description of Conversion Task
         * @summary show conversion
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversion(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversionTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get logs of Conversion Task (redirect to logs file)
         * @summary logs conversion
         * @param {string} id Conversion Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversionLogs(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversionLogs(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List conversion tasks for user
         * @summary List conversions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConversionTask>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary show resource
         * @param {string} id Resource id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResource(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResource(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary show resource\'s share_options
         * @param {string} id Resource id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShareOptions(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShareOptions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary show version
         * @param {string} id Version id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary files version
         * @param {string} id Version id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersionFiles(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersionFiles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary enter password for resource share_option
         * @param {string} id Resource id
         * @param {ResourceAuthPasswordRequest} [resourceAuthPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resourceAuthPassword(id: string, resourceAuthPasswordRequest?: ResourceAuthPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resourceAuthPassword(id, resourceAuthPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update resource\'s share_options
         * @param {string} id Resource id
         * @param {UpdateShareOptionsRequest} [updateShareOptionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateShareOptions(id: string, updateShareOptionsRequest?: UpdateShareOptionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateShareOptions(id, updateShareOptionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Start anonym conversion
         * @summary convert_anonym op
         * @param {ConvertAnonymRequest} [convertAnonymRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertAnonym(convertAnonymRequest?: ConvertAnonymRequest, options?: any): AxiosPromise<ConversionTask> {
            return localVarFp.convertAnonym(convertAnonymRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get description of Conversion Task
         * @summary show conversion
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversion(id: string, options?: any): AxiosPromise<ConversionTask> {
            return localVarFp.getConversion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get logs of Conversion Task (redirect to logs file)
         * @summary logs conversion
         * @param {string} id Conversion Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversionLogs(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getConversionLogs(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List conversion tasks for user
         * @summary List conversions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversions(options?: any): AxiosPromise<Array<ConversionTask>> {
            return localVarFp.getConversions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary show resource
         * @param {string} id Resource id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResource(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getResource(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary show resource\'s share_options
         * @param {string} id Resource id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShareOptions(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getShareOptions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary show version
         * @param {string} id Version id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getVersion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary files version
         * @param {string} id Version id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionFiles(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getVersionFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary enter password for resource share_option
         * @param {string} id Resource id
         * @param {ResourceAuthPasswordRequest} [resourceAuthPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourceAuthPassword(id: string, resourceAuthPasswordRequest?: ResourceAuthPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.resourceAuthPassword(id, resourceAuthPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update resource\'s share_options
         * @param {string} id Resource id
         * @param {UpdateShareOptionsRequest} [updateShareOptionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShareOptions(id: string, updateShareOptionsRequest?: UpdateShareOptionsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateShareOptions(id, updateShareOptionsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Start anonym conversion
     * @summary convert_anonym op
     * @param {ConvertAnonymRequest} [convertAnonymRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public convertAnonym(convertAnonymRequest?: ConvertAnonymRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).convertAnonym(convertAnonymRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get description of Conversion Task
     * @summary show conversion
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConversion(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConversion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get logs of Conversion Task (redirect to logs file)
     * @summary logs conversion
     * @param {string} id Conversion Task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConversionLogs(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConversionLogs(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List conversion tasks for user
     * @summary List conversions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConversions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConversions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary show resource
     * @param {string} id Resource id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getResource(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getResource(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary show resource\'s share_options
     * @param {string} id Resource id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getShareOptions(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getShareOptions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary show version
     * @param {string} id Version id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVersion(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVersion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary files version
     * @param {string} id Version id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVersionFiles(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVersionFiles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary enter password for resource share_option
     * @param {string} id Resource id
     * @param {ResourceAuthPasswordRequest} [resourceAuthPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resourceAuthPassword(id: string, resourceAuthPasswordRequest?: ResourceAuthPasswordRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).resourceAuthPassword(id, resourceAuthPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update resource\'s share_options
     * @param {string} id Resource id
     * @param {UpdateShareOptionsRequest} [updateShareOptionsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateShareOptions(id: string, updateShareOptionsRequest?: UpdateShareOptionsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateShareOptions(id, updateShareOptionsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



